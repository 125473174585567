/**
 * Some common constants used throughout the application.
 */

export const EMPTY_DATA = "-";
export const DEFAULT_LOCALE = "en-US";
export const SPACE = " ";
export const HYPHEN = "-";
export const EMPTY_STRING = "";
export const COLON = ":";

export const CS_PACE_CASE_MANAGEMENT = "CSPCM:";
export const AGENT_ACTOR_TYPE = "AGENT";
export const UPDATE_PAGE_REFRESH_INTERVAL = 3000;

export const CASE_SUMMARY_MAX_LENGTH = 5000;
export const CASE_TITLE_MAX_LENGTH = 200;
export const CASE_ANNOTATION_MAX_LENGTH = 5000;
export const CASE_ACTION_COMMENT_MAX_LENGTH = 60;
export const MAX_LENGTH_VALIDATION_MSG = (maxLength) => `The field shouldn't exceed ${maxLength} characters limit`;

export const SOMETHING_WRONG_ERROR_MSG = "Something went wrong. Please retry after sometime!";
export const AUTHENTICATION_FAILED_ERROR_MSG =
    "Authentication Failed. Please authenticate by refreshing the page and wait for popup to close automatically.";

/**
 * Function to validate the character limit.
 * @param maxLength - The max allowed limit.
 * @param data - The data on which validation needs to be performed
 */
export const validateCharacterLimit = (maxLength: number, data: string) => {
    return data.length > maxLength
        ? { errorMsg: `The field shouldn't exceed ${maxLength} limit`, isError: true }
        : { errorMsg: EMPTY_STRING, isError: false };
};

/**
 * Function to validate emailId.
 * @param emailId - EmailId to validate
 * @returns True if validation pass else false.
 */
export const validateEmailId = (emailId: string) => {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(emailId.toLowerCase());
};

/**
 * Function to retrieve alias and owner name from emailId. For example - guptshub@amazon.com have guptshub as userName and amazon as ownerName.
 * @param agentEmailId - EmailId from which userName and ownerName needs to be retrieved.
 * @returns agentUserName and agentOwnerName
 */
export const getAgentAliasAndOwner = (agentEmailId: string) => {
    const agentDetails = agentEmailId.split("@");
    return [agentDetails[0], agentDetails[1]?.split(".")[0]];
};
