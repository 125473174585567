import PhoenixAPI, {
    ClientConfig,
    CallResponse,
    SlapshotResponse,
    PhoenixRequest,
    SlapshotContract,
    LogLevel
} from "@amzn/csphoenix-react-client";
import {
    AUTHENTICATION_FAILED_ERROR_MSG,
    CS_PACE_CASE_MANAGEMENT,
    SOMETHING_WRONG_ERROR_MSG
} from "../constants/CommonConstants";
import { LANG_PARAM, PGW_PARAM } from "../constants/URLParamsConstants";
import MetricUtil from "../utils/MetricUtil";

const clientConfig: Record<string, ClientConfig> = {
    local: {
        tokenCookieName: "pgw-local",
        clientStateCookieName: "papyrus-local",
        redirectEndpoint: "https://local.pgw.cs.a2z.com:3000/",
        clientId: "pgw-local"
    },
    alpha: {
        tokenCookieName: "cspcm-test-token",
        clientStateCookieName: "cspcm-test-state",
        redirectEndpoint: "https://local.pgw.cs.a2z.com:3000/",
        clientId: "CSPaceCaseManagement-Beta"
    },
    beta: {
        tokenCookieName: "cspcm-test-token",
        clientStateCookieName: "cspcm-test-state",
        redirectEndpoint: "https://web.beta.eu.pace-case-management.cs.amazon.dev/",
        clientId: "CSPaceCaseManagement-Beta"
    },
    "gamma-eu": {
        tokenCookieName: "cspcm-prod-token",
        clientStateCookieName: "cspcm-prod-state",
        redirectEndpoint: "https://web.master.eu.pace-case-management.cs.amazon.dev/",
        clientId: "CSPaceCaseManagement-Prod"
    },
    "prod-eu": {
        tokenCookieName: "cspcm-prod-token",
        clientStateCookieName: "cspcm-prod-state",
        redirectEndpoint: "https://web.prod.eu.pace-case-management.cs.amazon.dev/",
        clientId: "CSPaceCaseManagement-Prod"
    },
    "gamma-na": {
        tokenCookieName: "cspcm-prod-token",
        clientStateCookieName: "cspcm-prod-state",
        redirectEndpoint: "https://web.master.na.pace-case-management.cs.amazon.dev/",
        clientId: "CSPaceCaseManagement-Prod"
    },
    "prod-na": {
        tokenCookieName: "cspcm-prod-token",
        clientStateCookieName: "cspcm-prod-state",
        redirectEndpoint: "https://web.prod.na.pace-case-management.cs.amazon.dev/",
        clientId: "CSPaceCaseManagement-Prod"
    }
};

export default class AuthContainer {
    private static phoenixAPI: PhoenixAPI;

    public static callCSALT = (
        request: PhoenixRequest,
        successCallback: (response: string) => any,
        errorCallback: (error: string) => any,
        metricName: string
    ) => {
        const startTime = Date.now();
        AuthContainer.getPhoenixInstance()
            .csalt(request)
            .then((response: CallResponse) =>
                AuthContainer._csaltAPIResponseCallback(response, successCallback, errorCallback, metricName, startTime)
            )
            .catch((error) => {
                MetricUtil.putLatencyMetric(metricName + "Exception", startTime);
                errorCallback(SOMETHING_WRONG_ERROR_MSG);
                AuthContainer.log(LogLevel.ERROR, CS_PACE_CASE_MANAGEMENT + metricName + JSON.stringify(error));
            });
    };

    public static callSlapshot = (
        request: SlapshotContract,
        successCallback: (response: string) => any,
        errorCallback: (error: string) => any,
        metricName: string
    ) => {
        const startTime = Date.now();
        AuthContainer.getPhoenixInstance()
            .callPhoenixSlapshot(request)
            .then((response: SlapshotResponse) =>
                AuthContainer._slapshotAPIResponseCallback(
                    response,
                    successCallback,
                    errorCallback,
                    metricName,
                    startTime
                )
            )
            .catch((error) => {
                MetricUtil.putLatencyMetric(metricName + "Exception", startTime);
                errorCallback(SOMETHING_WRONG_ERROR_MSG);
                AuthContainer.log(LogLevel.ERROR, CS_PACE_CASE_MANAGEMENT + metricName + JSON.stringify(error));
            });
    };

    public static getAuthenticatedUserId = () => {
        const jwtState = AuthContainer.getPhoenixInstance().jwtState;
        return jwtState ? jwtState.getUserId() : undefined;
    };

    public static getAuthenticatedUserAlias = () => {
        return AuthContainer.getPhoenixInstance()
            .jwtState?.getSubject()
            .split("@")[0];
    };

    public static getAuthenticatedUserOwner = () => {
        return AuthContainer.getPhoenixInstance()
            .jwtState?.getSubject()
            .split("@")[1];
    };

    private static _slapshotAPIResponseCallback = (
        slapshotResponse: SlapshotResponse,
        successCallback: (response: string) => any,
        errorCallback: (error: string) => any,
        metricName: string,
        startTime: number
    ) => {
        if (slapshotResponse.response.status === 401) {
            AuthContainer.getPhoenixInstance().startAuth((error) => {
                errorCallback(AUTHENTICATION_FAILED_ERROR_MSG);
                AuthContainer.log(LogLevel.ERROR, CS_PACE_CASE_MANAGEMENT + metricName + JSON.stringify(error));
            });
            MetricUtil.putLatencyMetric("Slapshot:AuthFailure", startTime);
        } else if (slapshotResponse.response.ok) {
            successCallback(slapshotResponse.json);
            MetricUtil.putLatencyMetric(metricName + "Success", startTime);
        } else {
            errorCallback(SOMETHING_WRONG_ERROR_MSG);
            MetricUtil.putLatencyMetric(metricName + "StatusNotOK", startTime);
        }
    };

    private static _csaltAPIResponseCallback = (
        csaltResponse: CallResponse,
        successCallback: (response: string) => any,
        errorCallback: (error: string) => any,
        metricName: string,
        startTime: number
    ) => {
        if (csaltResponse.httpStatus === 401) {
            AuthContainer.getPhoenixInstance().startAuth((error) => {
                AuthContainer.log(LogLevel.ERROR, CS_PACE_CASE_MANAGEMENT + metricName + JSON.stringify(error));
                errorCallback(AUTHENTICATION_FAILED_ERROR_MSG);
            });
            MetricUtil.putLatencyMetric("CSALT:AuthFailure", startTime);
        } else if (csaltResponse.callResult && csaltResponse.callResult.resultJson) {
            const response = JSON.parse(csaltResponse.callResult.resultJson);
            if (response?.errors?.length > 0) {
                MetricUtil.putLatencyMetric(metricName + "Error", startTime);
                errorCallback(SOMETHING_WRONG_ERROR_MSG);
                AuthContainer.log(
                    LogLevel.ERROR,
                    CS_PACE_CASE_MANAGEMENT + metricName + JSON.stringify(response.errors)
                );
            } else {
                successCallback(response.data);
                MetricUtil.putLatencyMetric(metricName + "Success", startTime);
            }
        } else {
            errorCallback(SOMETHING_WRONG_ERROR_MSG);
            MetricUtil.putLatencyMetric(metricName + "StatusNotOK", startTime);
        }
    };

    public static getPhoenixInstance() {
        return AuthContainer.phoenixAPI;
    }

    public static log = (loglevel: LogLevel, msg: string) => {
        switch (loglevel) {
            case LogLevel.ERROR:
                console.error(msg);
                break;
            case LogLevel.DEBUG:
                console.debug(msg);
                break;
            case LogLevel.INFO:
                console.info(msg);
                break;
            case LogLevel.TRACE:
                console.trace(msg);
                break;
            case LogLevel.WARN:
                console.warn(msg);
                break;
        }
        AuthContainer.getPhoenixInstance().log(loglevel, CS_PACE_CASE_MANAGEMENT + msg);
    };

    public static init(queryString: string) {
        const queryParams = new URLSearchParams(queryString);
        AuthContainer.phoenixAPI = new PhoenixAPI(
            queryParams.get(PGW_PARAM)!,
            queryParams.get(LANG_PARAM)!,
            clientConfig
        );
    }

    public static isProduction() {
        return AuthContainer.getPhoenixInstance().isProduction();
    }
}
