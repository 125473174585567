import PropTypes from "prop-types";
import React from "react";
import Spinner from "@amzn/awsui-components-react-v3/polaris/spinner";
import TextContent from "@amzn/awsui-components-react-v3/polaris/text-content";
import "./styles.css";
import Box from "@amzn/awsui-components-react-v3/polaris/box";
import Button from "@amzn/awsui-components-react-v3/polaris/button";
import AuthContainer from "../../containers/AuthContainer";
import { LogLevel } from "@amzn/csphoenix-react-client";

/**
 * Presentation Component showing that some data is loading in the background or show error.
 * @param error - Parameter containing the error details if any exception occurs during data loading.
 */
const LoadingComponent = ({ error }) => {
    if (error) {
        AuthContainer.log(LogLevel.ERROR, "Error occured while loading page. Error message is " + error);
        return (
            <Box textAlign="center" padding={{ top: "l" }}>
                <Box fontWeight="bold">Error while loading page!</Box>
                <Box data-testid="error">Error message is: {JSON.stringify(error)}</Box>
                <Box padding={{ top: "s" }}>
                    <Button onClick={() => window.location.reload()}>Refresh Page</Button>
                </Box>
            </Box>
        );
    } else {
        return (
            <div className="loading-spinner">
                <Spinner size="large" variant="normal" />
                <TextContent>
                    <p>Loading...</p>
                </TextContent>
            </div>
        );
    }
};

LoadingComponent.propTypes = {
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default LoadingComponent;
