import React from "react";
import PropTypes from "prop-types";
import AppLayout from "@amzn/awsui-components-react-v3/polaris/app-layout";
import { withRouter } from "react-router-dom";
import Routes from "../routes";
import AuthContainer from "./AuthContainer";

const App = ({ location }) => {
    AuthContainer.init(location.search);
    if (AuthContainer.getPhoenixInstance().isProduction()) {
        console.log = () => undefined;
        console.warn = () => undefined;
        console.error = () => undefined;
        console.trace = () => undefined;
        console.info = () => undefined;
    }
    return (
        <div className="awsui">
            <AppLayout content={Routes} toolsHide={true} navigationHide={true} />
        </div>
    );
};

export default withRouter(App);

App.propTypes = {
    location: PropTypes.any.isRequired
};
