import { combineReducers } from "redux";
import { default as filter } from "./filter";

/**
 * Returns the combined reducer.
 * @param state
 * @param action
 */

export default combineReducers({
  filter,
});
