import "react-hot-loader/patch";
import React from "react";
import { render } from "react-dom";
import { AppContainer } from "react-hot-loader";
import { configureStore, history } from "./store/configureStore";
import Root from "./containers/Root";
import "./i18n/i18n";
import "@amzn/awsui-global-styles/polaris.css";
import LoadingComponent from "./components/Loading/LoadingComponent";
const store = configureStore();

render(
  <AppContainer>
    <React.Suspense fallback={<LoadingComponent />}>
      <Root store={store} history={history} />
    </React.Suspense>
  </AppContainer>,
  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept("./containers/Root", () => {
    const newConfigureStore = import("./store/configureStore");
    const newStore = newConfigureStore.configureStore();
    const newHistory = newConfigureStore.history;
    const NewRoot = import("./containers/Root").default;
    render(
      <AppContainer>
        <NewRoot store={newStore} history={newHistory} />
      </AppContainer>,
      document.getElementById("root")
    );
  });
}
