/**
 * URL Query Parameters read by this application.
 */

export const USECASE_SESSION_ID_PARAM = "usecaseSessionId";
export const CUSTOMER_ID_PARAM = "customerId";
export const MARKETPLACE_ID_PARAM = "marketplaceId";
export const LANG_PARAM = "lang";
export const PGW_PARAM = "pgw";
export const AGENT_ID_PARAM = "agentId";
export const CASE_ID_PARAM = "caseId";
export const CONTACT_ID_PARAM = "contactId";
