/**
 * File setting up i18next Localization.
 * It picks the language from lang query parameter and fallbacks to en-US if nothing is specified.
 */

import i18next from "i18next";
import XHR from "i18next-http-backend";
import ICU from "i18next-icu";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import languageMap from "@katal/localization/dist/webpack/localization-loader!";

i18next
    .use(ICU)
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: "en-US",
        interpolation: {
            // not needed for react as it escapes by default
            escapeValue: false
        },
        load: "currentOnly",
        backend: {
            loadPath: (locale) => {
                const filePath = languageMap[locale];
                return "/" + filePath;
            }
        },
        detection: {
            // order and from where user language should be detected
            order: ["querystring", "navigator"],
            lookupQuerystring: "lang"
        },
        react: {
            // ensure all required language files are loaded before rendering
            wait: true
        }
    });

export default i18next;
