import { MetricType } from "@amzn/csphoenix-react-client";
import AuthContainer from "../containers/AuthContainer";
import { CS_PACE_CASE_MANAGEMENT } from "../constants/CommonConstants";
/**
 * Utility class to publish Metrics.
 */
export default class MetricUtil {
    /**
     * Function to publish count metric.
     *
     * @param metricName - The name of the metric.
     * @param count - The count of that metric.
     */
    static putCountMetric(metricName: string, count: number): void {
        AuthContainer.getPhoenixInstance().addMetric(CS_PACE_CASE_MANAGEMENT + metricName, count, MetricType.COUNT);
    }

    /**
     * Function to publish Latency Metric with Success/Failure count.
     *
     * @param metricName - The name of the metric.
     * @param startTime - StartTime to calculate the latency.
     */
    static putLatencyMetric(metricName: string, startTime: number): void {
        AuthContainer.getPhoenixInstance().addMetric(
            CS_PACE_CASE_MANAGEMENT + metricName,
            Date.now() - startTime,
            MetricType.TIME
        );
    }
}
