import React from "react";
import Button from "@amzn/awsui-components-react-v3/polaris/button";

const Home = () => {
  return (
    <div>
      Welcome to sample application provided by Spinnaker team for ART Web
      products and Integrations.
      <Button variant="primary">Button</Button>
    </div>
  );
};

export default Home;
