import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import { AuthReceiver } from "@amzn/csphoenix-react-client";

/**
 * Used to enable dynamic import of components
 */
import MyLoadable from "./components/Loading/LoadableComponent";

const CaseDetailsContainer = MyLoadable({
  loader: () => import("./containers/CaseDetails/CaseDetailsContainer"),
});

const CustomerCaseManagerContainer = MyLoadable({
  loader: () => import("./containers/CustomerCaseManager/CustomerCaseManagerContainer"),
});

const AgentCaseManagerContainer = MyLoadable({
  loader: () => import("./containers/AgentCaseManager/AgentCaseManagerContainer"),
});

const CreateCaseContainer = MyLoadable({
  loader: () => import("./containers/CreateCase/CreateCaseContainer"),
});

export default (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/case-details" component={CaseDetailsContainer} />
      <Route path="/customer-case-manager" component={CustomerCaseManagerContainer} />
      <Route path="/agent-case-manager" component={AgentCaseManagerContainer} />
      <Route path="/create-case" component={CreateCaseContainer} />
      <Route path="/:locale/auth/:cookieName" component={AuthReceiver} />
    </Switch>
);
